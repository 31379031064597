import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Artykułu nie znaleziono" />
        <h1>Artykułu nie znaleziono</h1>
        <p>Ten artykuł nie istnieje, skorzystaj z wyszukiwarki, żeby znaleźć ten, o który Ci chodziło.</p>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
